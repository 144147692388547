import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

/* modules */

import login from './modules/login'
import registrazione from './modules/registrazione'
import utenti from './modules/utenti'
import istituti from './modules/istituti'
import scuole from './modules/scuole'
import classi from './modules/classi'
import percorsi from './modules/percorsi'
import lezioni from './modules/lezioni'
import risorse from './modules/risorse'
import messaggi from './modules/messaggi'
import tests from './modules/tests' // da seitoscana.it/api
import comuni from './modules/comuni' //


Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*' }
axios.defaults.headers.common = { 'Content-Type': 'application/json' } // INSERITO PERCHè IL SERVER DAVA ERRORI NEI LOG


export default new Vuex.Store({

    state: {
        errors: null,
        success: null,
        loading: false
    },

    mutations: {
        POST_ERRORS: (state, payload) => {
            state.errors = payload;
        },
        POST_SUCCESS: (state, payload) => {
            state.success = payload;
        },

        LOADING_STATUS: (state, payload) => {
            state.loading = payload;
        }
    },

    actions: {
        SET_ERRORS: (context, message) => {
            context.commit("POST_ERRORS", message)
        },
        SET_SUCCESS: (context, message) => {
            context.commit("POST_SUCCESS", message)
        },
        SET_LOADING: (context, status) => {
            context.commit("LOADING_STATUS", status)
        }

    },

    modules: {
        login,
        registrazione,
        utenti,
        istituti,
        scuole,
        classi,
        percorsi,
        lezioni,
        risorse,
        messaggi,
        tests,
        comuni
    }
})