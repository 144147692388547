const resource_uri = '/messaggi';

const state = {
    messaggi: []
};

const getters = {
    getMessaggi: state => {
        return state.messaggi
    },

    getMessaggiNonLetti: state => {
        if(state.messaggi && state.messaggi.length > 0) {
            const userData = JSON.parse(localStorage.getItem('user'))
            const userId = userData.user.id
            return state.messaggi.filter(messaggio => (messaggio.letto == 0 && messaggio.mittente.id !== userId) )
        } else {
            return state.messaggi = []
        }
    }

};

const actions = {

    fetchMessaggi({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_MESSAGGI', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchMessaggiFromClasse({ commit }, data, credentials) {
        return axios.get(resource_uri, data, credentials)
            .then((response) => {
                const classe = Object.keys(response.data)[0]
                commit('SET_MESSAGGI', response.data[classe])
            })
            .catch(e => {
                console.log(e)
            })
    },

    addMessaggio({ commit, rootState }, messaggio, credentials) {
        return axios.post(resource_uri, messaggio, credentials)
            .then((response) => {
                commit('NEW_MESSAGGIO', messaggio);
                rootState.errors = null;
            })

    },

    updateMessaggio({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_MESSAGGIO', data)
                }
            )
    },

    deleteMessaggio({ commit, rootState }, data, credentials) {
        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_MESSAGGIO', data);
                }
            )
    }

};

const mutations = {
    SET_MESSAGGI(state, messaggi) {
        state.messaggi = messaggi
    },

    NEW_MESSAGGIO(state, messaggio) {
        if(state.messaggi) {
            state.messaggio = state.messaggi.unshift(messaggio);
        }else {
            state.messaggio = state.messaggi
        }
    },

    UPDATE_MESSAGGIO(state, messaggio) {
        state.messaggio = messaggio;
    },

    DELETE_MESSAGGIO(state, messaggio) {
        if(state.messaggi) {
            state.messaggi = state.messaggi.filter(c => messaggio.id !== c.id)
        }
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}