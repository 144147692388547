const resource_uri = '/percorsi';

const state = {
    percorsi: [],
    percorso: [],
    last: []
};

const getters = {
    getPercorsi: state => {
        return state.percorsi
    },

    getPercorso: state => {
        return state.percorso
    },
    getLastCreated: state => {
        return state.last
    },
};

const actions = {

    fetchPercorsiHome({ commit }) {
        return axios.get('/lista-percorsi')
            .then((response) => {
                commit('SET_PERCORSI', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchPercorsi({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_PERCORSI', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchPercorso({ commit }, data, credentials) {
        commit('SET_PERCORSO', null)
        return axios.get(resource_uri + '/' + data.id, credentials)
            .then((response) => {
                commit('SET_PERCORSO', response.data)
                    //localStorage.setItem("percorso", JSON.stringify(response.data))
            })
            .catch(e => {
                console.log(e)
            })
    },

    addPercorso({ commit, rootState }, percorso, credentials) {
        return axios.post(resource_uri, percorso, credentials)
            .then((response) => {
                commit('NEW_LAST', response.data);
                commit('NEW_PERCORSO', percorso);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updatePercorso({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_PERCORSO', data)
                    rootState.errors = null;
                    rootState.success = 'Percorso salvato!';
                }
            )
    },

    deletePercorso({ commit, rootState }, data, credentials) {

        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_PERCORSO', data);
                    rootState.errors = null;
                    rootState.success = data.titolo + ' eliminato!';
                }
            )
    }



};

const mutations = {
    SET_PERCORSI(state, percorsi) {
        state.percorsi = percorsi
    },


    SET_PERCORSO(state, percorso) {
        state.percorso = percorso
    },

    NEW_PERCORSO(state, percorso) {
        state.percorso = state.percorsi.unshift(percorso);
    },

    NEW_LAST(state, percorso) {
        state.last = percorso;
    },

    UPDATE_PERCORSO(state, percorso) {
        state.percorso = percorso;
    },

    DELETE_PERCORSO(state, percorso) {
        state.percorsi = state.percorsi.filter(c => percorso.id !== c.id)
    }


};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}