const resource_uri = '/scuole';

const state = {
    scuole: [],
    allScuole: [],
    scuola: [],
};

const getters = {
    getScuole: state => {
        return state.scuole
    },

    getScuoleSearch: (state) => (search) => {
        if (!search) {
            return state.scuole = state.allScuole
        }
        const s = search.toLowerCase()
        state.scuole = state.allScuole.filter(
            scuola => (
                (scuola.nome && scuola.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (scuola.citta && scuola.citta.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (scuola.comune && scuola.comune.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (scuola.istituto && scuola.istituto.nome && scuola.istituto.nome.toLowerCase().indexOf(s.toLowerCase()) > -1)
            )
        )
    }
};

const actions = {

    fetchScuole({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_SCUOLE', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addScuola({ commit, rootState }, scuola, credentials) {
        return axios.post(resource_uri, scuola, credentials)
            .then((response) => {
                commit('NEW_SCUOLA', scuola);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateScuola({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_SCUOLA', data)
                    rootState.errors = null;
                    rootState.success = 'Scuola salvata!';
                }
            )
            .catch(e => {
                rootState.success = null;
                rootState.errors = e.response.data;
            })
    },

    deleteScuola({ commit, rootState }, data, credentials) {

        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_SCUOLA', data);
                    rootState.errors = null;
                    rootState.success = data.nome + ' eliminata!';
                }
            )
            .catch(e => {
                rootState.success = null;
                rootState.errors = e.response.data;
            })
    }



};

const mutations = {
    SET_SCUOLE(state, scuole) {
        state.scuole = scuole
        state.allScuole = scuole
    },

    NEW_SCUOLA(state, scuola) {
        state.scuola = state.scuole.unshift(scuola);
    },

    UPDATE_SCUOLA(state, scuola) {
        state.scuola = scuola;
    },

    DELETE_SCUOLA(state, scuola) {
        state.scuole = state.scuole.filter(c => scuola.id !== c.id)
    }


};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}