<template>
    <button class="button button--small button--outline" @click="toggleQuizButton" v-if="percorsoHaveQuiz">
      <div class="play" v-if="getClasse.quiz == 0"></div>
      <div class="stop" v-else></div>
      Modalità test
    </button>
</template>

<script>
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ToggleQuiz',

  data () {
    return {
    }
  },

  computed: {

     ...mapGetters('classi',[
        'getClasse'
     ]),

      classeIdFromParams () {
          return this.$route.params.classeId
      },

      percorsoHaveQuiz () {
       if(this.getClasse && this.getClasse.percorso && this.getClasse.percorso.test)
        return true
      }
  },

  methods: {

    ...mapActions('classi',[
        'updateClasse',
        'fetchClasse'
    ]),

    toggleQuizButton() {
        this.updateClasse({
            id:  this.getClasse.id,
            quiz: !this.getClasse.quiz
        }).then((response) => {
              this.$store.dispatch('classi/fetchClasse', {
              id: this.getClasse.id
            })
        }).catch(e => {
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
        })
    }

  },
  /*
    beforeMount() {
      this.$store.dispatch('classi/fetchClasse', {
            id: this.classeIdFromParams
          })
    },
  */

}
</script>