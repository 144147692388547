const resource_uri = '/istituti';

const state = {
    allIstituti: [],
    istituti: [],
    istituto: [],
    last: []
};

const getters = {
    getIstituti: state => {
        return state.istituti
    },

    getIstituto: state => {
        return state.istituto
    },

    getLastCreated: state => {
        return state.last
    },

    getIstitutiSearch: (state) => (search) => {
        if (!search) {
            return state.istituti = state.allIstituti
        }
        const s = search.toLowerCase()
        state.istituti = state.allIstituti.filter(istituto => (istituto.nome && istituto.nome.toLowerCase().indexOf(s.toLowerCase()) > -1))
    }

};

const actions = {

    fetchIstituti({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_ISTITUTI', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchIstituto({ commit }, istituto, credentials) {
        return axios.get(resource_uri + '/' + istituto.id, credentials)
            .then((response) => {
                commit('SET_ISTITUTO', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addIstituto({ commit, rootState }, istituto, credentials) {
        return axios.post(resource_uri, istituto, credentials)
            .then((response) => {
                commit('NEW_LAST', response.data);
                commit('NEW_ISTITUTO', istituto);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateIstituto({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_ISTITUTO', data)
                    rootState.errors = null;
                    rootState.success = 'Istituto salvato!';
                }
            )
    },

    deleteIstituto({ commit, rootState }, data, credentials) {

        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_ISTITUTO', data);
                    rootState.errors = null;
                    rootState.success = data.nome + ' eliminato!';
                }
            )
    }


};

const mutations = {
    SET_ISTITUTI(state, istituti) {
        state.istituti = istituti
        state.allIstituti = istituti
    },

    SET_ISTITUTO(state, istituto) {
        state.istituto = istituto
    },

    NEW_ISTITUTO(state, istituto) {
        state.istituto = state.istituti.unshift(istituto);
    },

    NEW_LAST(state, istituto) {
        state.last = istituto;
    },

    UPDATE_ISTITUTO(state, istituto) {
        state.istituto = istituto;
    },

    DELETE_ISTITUTO(state, istituto) {
        state.istituti = state.istituti.filter(c => istituto.id !== c.id)
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}