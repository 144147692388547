<template>
    <span>
        <transition name="slideright">
            <div class="offcanvas" v-if="offcanvas">
                <div class="offcanvas--header">
                    <slot name="header"></slot>
                    <button @click="handleClose" class="close--button">
                        <svg class="icon" width="36" height="36"><use xlink:href="#close" /></svg>
                    </button>
                </div>
                <div class="offcanvas--content">
                    <slot name="content"></slot>
                    <slot name="form"></slot>
                </div>
                <div class="offcanvas--footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="offcanvas" class="overlay"></div>
       </transition>
    </span>
</template>

<script>
export default {
    name: 'UiOffCanvas',

    data() {
        return {
            offcanvasShow: ''
        }
    },

    props: {
        offcanvas: Boolean,
        offcanvasClass: String
    },
    methods: {
        handleClose() {
            this.offcanvasShow = false
            this.$emit('update:offcanvasShow', false)
        }
    }
}
</script>