import router from '../../router';

const state = {
    user: null
};

const mutations = {

    setUserData(state, userData) {
        state.user = userData
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('app_version', process.env.VUE_APP_VERSION)
        axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData() {
        localStorage.removeItem('user')
        localStorage.removeItem('lezioneid')
        localStorage.removeItem('risorsaid')
        localStorage.removeItem('app_version')
        location.reload()
    }

};

const actions = {
    login({ commit }, credentials) {
        return axios
            .post('/login', credentials)
            .then(({ data }) => {
                commit('setUserData', data)
                if (data.user.tipo === 'CLASSE') {
                    const idclasse = data.user.classe[0].id;
                    router.push({ name: 'Classe', params: { classeId: idclasse } })
                } else if (data.user.tipo === 'ADMIN') {
                    router.push({ name: 'EditorDashboard' })
                } else {
                    router.push({ name: 'Dashboard' })
                }

            })
    },
    logout({ commit }, credentials) {
        commit('clearUserData')
        return axios
            .get('/logout', credentials)
            .then(() => {})
    }
};
const getters = {
    user: state => state.user,
    isLogged: state => !!state.user,
    isAdmin: state => state.user && state.user.user.tipo === 'ADMIN',
    isEducatore: state => state.user && state.user.user.tipo === 'EDUCATORE',
    isInsegnante: state => state.user && state.user.user.tipo === 'INSEGNANTE',
    isClasse: state => state.user && state.user.user.tipo === 'CLASSE',
    classiUtente: state => state.user && state.user.user.classi
};

export default {
    state,
    mutations,
    getters,
    actions
}