const resource_uri = '/lezioni';

const state = {
    lezioni: [],
    allLezioni: []
};

const getters = {

    getLezioni: state => {
        return state.lezioni
    },

    getLezioniByPercorso: (state) => (percorso) => {
        return state.lezioni = state.allLezioni.filter(lezione => lezione.percorso_id === percorso)
    },

    getLezioniGenerali: state => {
        return state.lezioni = state.allLezioni.filter(lezione => lezione.percorso === null)
    },

    getLezione: state => {
        return state.lezione
    },
};

const actions = {
    fetchLezioni({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_LEZIONI', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchLezione({ commit }, data, credentials) {
        return axios.get(resource_uri + '/' + data.id, credentials)
            .then((response) => {
                commit('SET_LEZIONE', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addLezione({ commit, rootState }, lezione, credentials) {
        return axios.post(resource_uri, lezione, credentials)
            .then((response) => {
                commit('NEW_LEZIONE', lezione);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateLezione({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_LEZIONE', data)
                    rootState.errors = null;
                    rootState.success = 'Lezione salvatA!';
                }
            )
    },

    deleteLezione({ commit, rootState }, data, credentials) {
        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_LEZIONE', data);
                    rootState.errors = null;
                    rootState.success = data.titolo + ' eliminata!';
                }
            )
    }

};

const mutations = {
    SET_LEZIONI(state, lezioni) {
        state.lezioni = lezioni
        state.allLezioni = lezioni
    },

    SET_LEZIONE(state, lezione) {
        state.lezione = lezione
    },

    NEW_LEZIONE(state, lezione) {
        state.lezione = state.lezioni.unshift(lezione);
    },

    UPDATE_LEZIONE(state, lezione) {
        state.lezione = lezione;
    },

    DELETE_LEZIONE(state, lezione) {
        state.lezioni = state.lezioni.filter(c => lezione.id !== c.id)
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}