import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
const Dashboard = () =>
    import ('../views/Dashboard.vue')
    // AREA ADMIN EDITOR
const Editor = () =>
    import ('../views/Editor/Editor.vue')
const EditorDashboard = () =>
    import ('../views/Editor/Dashboard/EditorDashboard.vue')
const EditorIstituti = () =>
    import ('../views/Editor/Istituti/EditorIstituti.vue')
const EditorScuole = () =>
    import ('../views/Editor/Scuole/EditorScuole.vue')
const EditorClassi = () =>
    import ('../views/Editor/Classi/EditorClassi.vue')
const EditorPercorsi = () =>
    import ('../views/Editor/Percorsi/EditorPercorsi.vue')
const EditorLezioni = () =>
    import ('../views/Editor/Percorsi/EditorLezioni.vue')
const EditorQuiz = () =>
    import ('../views/Editor/Percorsi/EditorQuiz.vue')
const EditorUtenti = () =>
    import ('../views/Editor/Utenti/EditorUtenti.vue')
const EditorLezioniGenerali = () =>
    import ('../views/Editor/Lezioni/EditorLezioniGenerali.vue')
    // FRONTEND
const Home = () =>
    import ('../views/Home.vue')
const Classe = () =>
    import ('../views/Classe.vue')
const Lezione = () =>
    import ('../views/Lezione.vue')
const Risorsa = () =>
    import ('../views/Risorsa.vue')
    // Layouts
import AppLayout from '@/layouts/AppLayout.vue'
import SiteLayout from '@/layouts/SiteLayout.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: SiteLayout,
        meta: {
            disableIfLoggedIn: true
        },
        children: [{
            path: '',
            name: 'Home',
            component: Home
        }]
    },
    {
        path: '/app',
        meta: {
            auth: true,
        },
        component: AppLayout,
        children: [{
                path: '',
                name: 'Dashboard',
                component: Dashboard,
                props: true,
                meta: {
                    disableDashboard: true,
                }
            },
            {
                path: 'e',
                component: Editor,
                props: true,
                meta: {
                    disableEditor: true,
                },
                children: [{
                    path: '',
                    name: 'EditorDashboard',
                    component: EditorDashboard,
                    props: true
                }, {
                    path: 'classi',
                    name: 'EditorClassi',
                    component: EditorClassi,
                    props: true
                }, {
                    path: 'istituti',
                    name: 'EditorIstituti',
                    component: EditorIstituti,
                    props: true
                }, {
                    path: 'scuole',
                    name: 'EditorScuole',
                    component: EditorScuole,
                    props: true
                }, {
                    path: 'percorsi',
                    name: 'EditorPercorsi',
                    component: EditorPercorsi,
                    props: true
                }, {
                    path: 'percorso/:percorsoId/lezioni',
                    name: 'EditorLezioni',
                    component: EditorLezioni,
                    props: true
                }, {
                    path: 'percorso/:percorsoId/quiz',
                    name: 'EditorQuiz',
                    component: EditorQuiz,
                    props: true
                }, {
                    path: 'utenti',
                    name: 'EditorUtenti',
                    component: EditorUtenti,
                    props: true
                }, {
                    path: 'lezioni',
                    name: 'EditorLezioniGenerali',
                    component: EditorLezioniGenerali,
                    props: true
                }]
            },
            {
                path: 'classe/:classeId',
                name: 'Classe',
                component: Classe,
                props: true,
                children: [{
                    path: ':lezioneId', //prima era lezioneId
                    name: 'Lezione', //prima era Lezione
                    component: Lezione, //prima era Lezione
                    props: true,
                    children: [{
                        path: ':risorsaId', //prima era lezioneId
                        name: 'Risorsa', //prima era Lezione
                        component: Risorsa, //prima era Lezione
                        props: true
                    }]
                }]
            }
        ]
    },
    {
        path: '/404',
        component: function() {
            return import ( /* webpackChunkName: "notfound" */ '../layouts/NotFoundLayout.vue')
        }
    },
    { path: '*', redirect: '/404' },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        // se non è loggato torna alla home
        next({
            name: 'Home'
        })
        return
    } else if (to.matched.some(record => record.meta.disableIfLoggedIn) && loggedIn) {
        // se è loggato va alla dashboard /app

        /****
         * aggiunto il 3/09 per sloggare utenti diversi da admin
        if (loggedIn.tipo !== 'ADMIN' && tipo !== 'EDUCATORE') {
            this.$store.dispatch('logout')
        }
       */

        /* slogga l'utente inserito per bloccare gli accessi quando non è attivo il sistema  */
        /*
        localStorage.removeItem('user');
        localStorage.removeItem('lezioneid');
        localStorage.removeItem('risorsaid');
        localStorage.removeItem('app_version');
        router.push('Home')
        */
        /* slogga l'utente  */


        next({
            name: 'Dashboard'
        })
    } else {
        next()
    }
    next()
})



router.beforeEach((to, from, next) => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.user && to.matched.some(record => record.meta.disableDashboard)) {

        /* slogga l'utente: inserito per bloccare gli accessi quando non è attivo il sistema  */
        // this.$store.dispatch('logout')
        /* slogga l'utente  */
        const tipo = user.user.tipo

        /******
        * 
        * aggiunto il 3/09 per sloggare utenti diversi da admin e/o educatore
        if (tipo !== 'ADMIN' && tipo !== 'EDUCATORE') {
            this.$store.dispatch('logout')
        }
        */

        // DASHBOARD

        if (tipo === 'ADMIN') {
            next({
                name: 'EditorDashboard'
            })
        } else if (tipo === 'CLASSE') {
            next({
                name: 'Classe',
                params: {
                    classeId: user.user.classe[0].id
                }
            })
        } else {
            next()
        }
        return

    } else if (user && to.matched.some(record => record.meta.disableEditor)) {
        // EDUCATORE
        const tipo = user.user.tipo
        if (tipo === 'EDUCATORE' || tipo === 'INSEGNANTE') {
            next({
                name: 'Dashboard'
            })
        } else if (tipo === 'CLASSE') {
            next({
                name: 'Classe',
                params: {
                    classeId: user.user.classe[0].id
                }
            })
        } else {
            next()
        }
        return

    }
    next()
})

router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
        window.location.reload()
    }
}); // onError


export default router