const resource_uri = '/users';
const invio_credenziali_uri = '/send-credenziali';

const state = {
    utenti: [],
    allutenti: [],
    insegnanti: [],
    educatori: [],
    admin: [],
    classi: [],
    utente: [],
    nome: ''
};

const getters = {
    getUtenti: state => {
        return state.utenti
    },
    getUtente: state => {
        return state.utente
    },
    getInsegnanti: state => {
        state.insegnanti = state.allutenti.filter(utente => utente.tipo === 'INSEGNANTE')
        return state.insegnanti
    },
    getEducatori: state => {
        state.educatori = state.allutenti.filter(utente => utente.tipo === 'EDUCATORE')
        return state.educatori
    },
    getClassi: state => {
        state.classi = state.utenti.filter(utente => utente.tipo === 'CLASSE')
    },
    getAdmin: state => {
        state.admin = state.utenti.filter(utente => utente.tipo === 'ADMIN')
    },
    getUtentiByType: (state) => (tipo) => {
        if (!tipo) return state.utenti = state.allutenti
        state.utenti = state.allutenti.filter(utente => utente.tipo === tipo)
    },

    getUtentiCredenzialiNonInviate: state => {
        return state.allutenti.filter(utente => (utente.tipo == 'INSEGNANTE' || utente.tipo == 'EDUCATORE') && !utente.credential_mail_send_at )
    },

    getUtentiSearch: (state) => (search) => {
        if (!search) {
            return state.utenti = state.allutenti
        }
        const s = search.toLowerCase()
        state.utenti = state.allutenti.filter(utente => (utente.nome && utente.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) || (utente.username && utente.username.toLowerCase().indexOf(s.toLowerCase()) > -1) || (utente.email && utente.email.toLowerCase().indexOf(s.toLowerCase()) > -1))
    }
};

const actions = {
    fetchUtenti({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_UTENTI', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchUtente({ commit }, data, credentials) {
        return axios.get(resource_uri + '/' + data.id, credentials)
            .then((response) => {
                commit('SET_UTENTE', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addUtente({ commit, rootState }, utente, credentials) {

        return axios.post(resource_uri, utente, credentials)
            .then((response) => {
                commit('NEW_UTENTE', utente);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateUtente({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_UTENTE', data)
                    rootState.errors = null;
                    rootState.success = 'Utente aggiornato!';
                }
            )
            .catch(e => {
                rootState.success = null;
                rootState.errors = e.response.data;
            })
    },

    inviaCredenziali({ commit, rootState }, utente, credentials) {

        return axios.post(invio_credenziali_uri, utente, credentials)
            .then((response) => {
                commit('UPDATE_UTENTE', utente);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    deleteUtente({ commit, rootState }, data, credentials) {

        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_UTENTE', data);
                    rootState.errors = null;
                    rootState.success = 'Utente ' + data.username + ' eliminato!';
                }
            )
    }


};

const mutations = {
    SET_UTENTI(state, utenti) {
        state.utenti = utenti
        state.allutenti = utenti
    },

    SET_UTENTE(state, utente) {
        state.utente = utente
    },

    NEW_UTENTE(state, utente) {
        state.utente = state.utenti.unshift(utente);
    },

    UPDATE_UTENTE(state, utente) {
        state.utente = utente;
    },

    DELETE_UTENTE(state, utente) {
        state.utenti = state.utenti.filter(c => utente.id !== c.id)
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}