const state = {
    comuni: [],
};

const getters = {
    getComuni: state => {
        return state.comuni
    },
};


const actions = {
    fetchComuni({ commit }) {

        return axios.get(process.env.VUE_APP_SEITOSCANA_API_URL + '/lista-comuni')
            .then((response) => {
                commit('SET_COMUNI', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

};

const mutations = {
    SET_COMUNI(state, comuni) {
        state.comuni = comuni
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}