const resource_uri = '/risorse';

const state = {
    risorse: [],
    allRisorse: []
};

const getters = {
    getRisorse: state => {
        return state.risorse
    },

    getRisorseByRisorsa: (state) => (lezione) => {
        return state.risorse = state.allRisorse.filter(risorsa => risorsa.lezione_id === lezione)
    },

    getRisorsa: state => {
        return state.risorsa
    },
};

const actions = {

    fetchRisorse({ commit }, credentials) {
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_RISORSE', response.data.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    fetchRisorsa({ commit }, data, credentials) {
        return axios.get(resource_uri + '/' + data.id, credentials)
            .then((response) => {
                commit('SET_RISORSA', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addRisorsa({ commit, rootState }, risorsa, credentials) {
        const userData = JSON.parse(localStorage.getItem('user'))
        const token = userData['token']
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
            }
        };
        return axios.post(resource_uri, risorsa, config)
            .then((response) => {
                commit('NEW_RISORSA', risorsa);
                rootState.errors = null;
                rootState.success = response.data.message;
            })

    },

    addVideoLink({ commit, rootState }, risorsa, credentials) {
        return axios.post(resource_uri, risorsa, credentials)
            .then((response) => {
                commit('NEW_RISORSA', risorsa);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateRisorsa({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_RISORSA', data)
                    rootState.errors = null;
                    rootState.success = 'Risorsa salvata!';
                }
            )
    },

    deleteRisorsa({ commit, rootState }, data, credentials) {
        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_RISORSA', data);
                    rootState.errors = null;
                    rootState.success = data.nome + ' eliminata!';
                }
            )
    }

};

const mutations = {
    SET_RISORSE(state, risorse) {
        state.risorse = risorse
        state.allRisorse = risorse
    },


    SET_RISORSA(state, risorsa) {
        state.risorsa = risorsa
    },

    NEW_RISORSA(state, risorsa) {
        state.risorsa = state.risorse.unshift(risorsa);
    },

    UPDATE_RISORSA(state, risorsa) {
        state.risorsa = risorsa;
    },

    DELETE_RISORSA(state, risorsa) {
        state.risorse = state.risorse.filter(c => risorsa.id !== c.id)
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}