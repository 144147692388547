import router from '../../router';
const resource_uri = '/classi';
const resource_uri_risultati = '/risultati';

const state = {
    classi: [],
    allClassi: [],
    classe: [],
    nome: ''
};

const getters = {
    getClassi: state => {
        return state.classi
    },

    getClassiWithoutTutor: state => {
        return state.classi.filter(classe => (classe.educatori && classe.educatori.length < 1))
    },

    getClassiWithoutPercorso: state => {
        return state.classi.filter(classe => !classe.percorso_id)
    },

    getClassiQuizAttivo: state => {
        return state.classi.filter(classe => (classe.quiz == 1))
    },

    getClasse: state => {
        return state.classe
    },

    getPercorso: state => {
        return state.classe.percorso
    },

    getClassiSearch: (state) => (search) => {

        if (!search) {
            return state.classi = state.allClassi
        }

        const s = search.toLowerCase()
        state.classi = state.allClassi.filter(
            classe => (
                (classe.nome && classe.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (classe.scuola && classe.scuola.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (classe.scuola && classe.scuola.comune.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (classe.percorso && classe.percorso.titolo && classe.percorso.titolo.toLowerCase().indexOf(s.toLowerCase()) > -1) ||
                (classe.insegnanti && classe.insegnanti.filter(function(obj) {
                    return (obj.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) || (obj.email.toLowerCase().indexOf(s.toLowerCase()) > -1)
                }))[0] ||
                (classe.educatori && classe.educatori.filter(function(obj) {
                    return (obj.nome.toLowerCase().indexOf(s.toLowerCase()) > -1) || (obj.email.toLowerCase().indexOf(s.toLowerCase()) > -1)
                }))[0]
            )
        )
    }

};

const actions = {
    fetchClassi({ commit, rootState }, credentials) {
        rootState.loading = true;
        return axios.get(resource_uri, credentials)
            .then((response) => {
                commit('SET_CLASSI', response.data.data)
                rootState.loading = false;
            })
            .catch(e => {
                rootState.loading = false;
            })
    },

    fetchClasse({ commit, rootState }, data, credentials) {

        return axios.get(resource_uri + '/' + data.id, credentials)
            .then((response) => {
                commit('SET_CLASSE', response.data)
                const idclasse = response.data.id;
                // se è una classe eseguo un redirect verso una lezione
                // se impostata nello storage si mostra l'ultima lezione visualizata, altrimenti si va nella prima lezione del percorso
                /*
                if (router.currentRoute.name == 'Classe') {
                    const idlezione = JSON.parse(localStorage.getItem('lezioneid')) || response.data.percorso.lezioni[0].id
                    router.push({ name: 'Lezione', params: { classeId: idclasse, lezioneId: idlezione } })
                }
                */
                // aggiungo la classe al body
                if (router.currentRoute.name == 'Classe' || router.currentRoute.name == 'Lezione' || router.currentRoute.name == 'Risorsa') {
                    document.body.classList.add(response.data.percorso.categoria)
                }

            })
            .catch(e => {
                console.log(e)
            })
    },

    destroyClasse({ commit }) {
        commit('RESET_CLASSE')
    },

    addClasse({ commit, rootState }, classe, credentials) {
        return axios.post(resource_uri, classe, credentials)
            .then((response) => {
                commit('NEW_CLASSE', classe);
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },

    updateClasse({ commit, rootState }, data, credentials) {
        const noQuiz = data.quiz === null
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_CLASSE', data)
                    rootState.errors = null;
                    if (noQuiz) {
                        rootState.success = 'Classe salvata!';
                    }

                }
            )
            .catch(e => {
                rootState.success = null;
                rootState.errors = e.response.data;
            })
    },


    deleteClasse({ commit, rootState }, data, credentials) {

        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('DELETE_CLASSE', data);
                    rootState.errors = null;
                    rootState.success = data.nome + ' eliminata!';
                }
            )
            .catch(e => {
                rootState.success = null;
                rootState.errors = e.response.data;
            })
    },

    salvaRisultatoQuiz({ commit, rootState }, data, credentials) {
        return axios.post(resource_uri_risultati, data, credentials)
            .then((response) => {
                rootState.errors = null;
            })

    }

};

const mutations = {
    SET_CLASSI(state, classi) {
        state.classi = classi
        state.allClassi = classi
    },

    SET_CLASSE(state, classe) {
        state.classe = classe
    },

    RESET_CLASSE(state) {
        state.classe = null
    },

    NEW_CLASSE(state, classe) {
        state.classe = state.classi.unshift(classe);
    },

    UPDATE_CLASSE(state, classe) {
        state.classe = classe;
    },

    DELETE_CLASSE(state, classe) {
        state.classi = state.classi.filter(c => classe.id !== c.id)
    }

};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}