import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/sass/app.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueMeta from 'vue-meta'
import Multiselect from 'vue-multiselect'
import VueYoutube from 'vue-youtube'

// components
import UiModal from '@/components/ui/Modal'
import UiOffCanvas from '@/components/ui/Offcanvas'
import UiPreloader from '@/components/ui/Preloader'
import UiDropdown from '@/components/ui/Dropdown'
import checkView from 'vue-check-view'

Vue.component('UiModal', UiModal)
Vue.component('UiOffCanvas', UiOffCanvas)
Vue.component('UiPreloader', UiPreloader)
Vue.component('UiDropdown', UiDropdown)

Vue.component('multiselect', Multiselect)

Vue.use(VueYoutube)
Vue.use(checkView)

Vue.use(vueSmoothScroll)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

window.axios = require('axios')

Vue.prototype.$const = {
    login: {
        utente: "Utente",
        password: "Password",
    }
}



new Vue({
    router,
    store,
    created() {
        const userInfo = localStorage.getItem('user')
        //const appVersion = localStorage.getItem('app_version')
            /*
            if (appVersion && appVersion != process.env.VUE_A.env.localPP_VERSION) {
                this.$store.dispatch('logout')
            }
            */

        if (userInfo) {
            const userData = JSON.parse(userInfo)
            this.$store.commit('setUserData', userData)
        }

        axios.interceptors.response.use(
            response => {
                if (this.$route.name !== 'Home') {

                    /* aggiunto il 3/09 per sloggare utenti diversi da admin
                    const userInfo = localStorage.getItem('user');
                    const userData = JSON.parse(userInfo);
                    if (userData.user.tipo !== 'ADMIN' && userData.user.tipo !== 'EDUCATORE') {
                        this.$store.dispatch('logout')
                    }
                    /* fine codice 3/09 */

                }
                return response
            },
            error => {
                if (error.response.status === 401) {
                    this.$store.dispatch('logout')
                }
                return Promise.reject(error)
            }
        )

    },
    render: function(h) { return h(App) }
}).$mount('#app')