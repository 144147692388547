<template>
  <div>
  <!-- errori passati con response message -->
  <div v-if="this.$store.state.errors && this.$store.state.errors.message">
    <div class="alert alert--error">
          {{ this.$store.state.errors.message }}
    </div>
  </div>

 <!-- errori passati tramite validate request -->
  <div v-else-if="this.$store.state.errors && !this.$store.state.errors.message">
    <div class="alert alert--error">
          <div v-for="error in this.$store.state.errors" :key="error.data">
            {{ error[0] }}
          </div>
    </div>
  </div>

  </div>

</template>

<script>
export default {
  name: 'ErrorsAlert',

  methods: {
    dismissErrors: function() {
      this.$store.state.errors = null
    }
  },

  mounted(){
    if(this.$store.state.errors) {
        setTimeout(()=>{
         // this.dismissErrors()
        },4000);
    }
    
  }

}
</script>
