const resource_uri = '/tests';

const state = {
    test: [],
    userResponses: []
};

const getters = {
    getTest: state => {
        return state.test
    }

};

const actions = {


    fetchTest({ commit }, test, credentials) {
        return axios.get(resource_uri + '/' + test.id, credentials)
            .then((response) => {
                commit('SET_TEST', response.data)
            })
            .catch(e => {
                console.log(e)
            })
    },

    addTest({ commit, rootState }, test, credentials) {
        return axios.post(resource_uri, test, credentials)
            .then((response) => {
                rootState.errors = null;
                rootState.success = response.data.message;
            })
    },


    updateTest({ commit, rootState }, data, credentials) {
        return axios.put(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    commit('UPDATE_TEST', data)
                    rootState.errors = null;
                    rootState.success = 'Test salvato!';
                }
            )
    },

    deleteTest({ commit, rootState }, data, credentials) {
        return axios.delete(resource_uri + '/' + data.id, data, credentials)
            .then(
                response => {
                    rootState.errors = null;
                    rootState.success = data.nome + ' eliminato!';
                }
            )
    }


};

const mutations = {

    SET_TEST(state, test) {
        state.test = test
    },

    UPDATE_TEST(state, test) {
        state.test = test;
    }


};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}