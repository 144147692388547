<template>
      <div class="form">
          <ErrorsAlert />
          <div v-if="!loading">
            <div class="field">
            <input type="text" placeholder="Utente" v-model="username"  name="username" autocomplete="off" class="rounded">
            </div>
            <div class="field">
            <input type="password" placeholder="Password" v-model="password"  name="password" autocomplete="off" class="rounded">
            </div>
            <div class="field">
            <button class="t-uppercase button" @click="login()" :disabled="disabled">Entra</button>
            </div>
          </div>
          <div v-else>
            loading
          </div>
      </div>
</template>


<script>
import ErrorsAlert from '@/components/ErrorsAlert.vue'

export default {
  name: 'LoginForm',

  data () {
    return {
      disabled: false,
      loading: false,
      username: '',
      password: '',
    }
  },

  props: {
    msg: String
  },

  components: {
    ErrorsAlert,
  },

  methods: {
    login () {
      this.disabled = true
      this.loading = true
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password
        })
        .then( () => {
          this.loading = false
          this.$store.state.errors = null          
        })
        .catch(e => {
          this.disabled = false
          this.loading = false
          this.$store.state.errors = e.response.data
        })
    }
   
  }
}
</script>