<template>
  <header id="AppHeader" :class="{ hide : hideHeader && scrollPos > 50}">
      <router-link :to="{ name: 'Dashboard'} " id="AppLogo" title="Dashboard">
        <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 360 63" style="enable-background:new 0 0 360 63;" xml:space="preserve">
            <path class="fill-primary" d="M4.9,23.5l-4,0l0-6.7l19.3,0c7.9,0,13.4,4.9,13.4,13c0,7.8-5.3,11-7.2,11.4v0.1c0,0,1.3,0.6,2.1,2.2l4.2,8.3
              c0.7,1.3,1.7,1.4,3,1.4l0.8,0l0,6.7l-4.2,0c-3.2,0-4.6-0.5-6-3.2L21,45.8c-0.9-1.7-1.8-2-4.1-2l-4,0l0,16.1l-8,0L4.9,23.5z
              M19.2,37c4,0,6.4-2.5,6.4-6.8c0-4.3-2.4-6.7-6.3-6.7l-6.4,0l0,13.5H19.2z"/>
            <path class="fill-primary" d="M39.6,36.8c0-0.9-0.5-1.3-1.3-1.3h-2.4l0-6.6l7.4,0c2.9,0,4.1,1.3,4.1,4.1l0,19c0,0.9,0.5,1.3,1.3,1.3H51
              l0,6.5l-7.4,0c-2.9,0-4.1-1.2-4.1-4.1L39.6,36.8z M39.9,16.7l6.7,0l0,7.1l-6.7,0L39.9,16.7z"/>
            <path class="fill-primary" d="M53.4,39.1l18.3,0l0,6.7l-18.3,0L53.4,39.1z"/>
            <path class="fill-percorso3" d="M100.2,15.9c5.5,0,15.5,2.3,15.5,9.6l0,5l-9.4,0v-2.3c0-2.3-3.5-3.3-6.1-3.3c-7.4,0-12.6,5.6-12.6,13.3
              c0,8.7,6.4,13.1,13.3,13.1c7.3,0,12.6-5.8,12.6-5.8l4.4,7.4c0,0-6.3,7.7-18,7.7c-14,0-23.1-9.9-23.1-22.3
              C76.9,26.2,85.5,15.9,100.2,15.9z"/>
            <path class="fill-percorso1" d="M162.4,52.4h1.1c1.2,0,1.8-0.3,2.2-1.6l15.1-41.3l9.8,0l15,41.3c0.4,1.3,1,1.6,2.2,1.6h1.1l0,7.9l-5.6,0
              c-3.6,0-4.9-0.9-6-4.1l-3.1-8.9l-17.2,0l-3.1,8.9c-1.1,3.2-2.4,4.1-5.9,4.1h-5.7V52.4z M192.2,39.6l-4.4-12.7
              c-0.9-2.8-2-7.6-2-7.6h-0.1c0,0-1.1,4.8-2,7.6l-4.4,12.7L192.2,39.6z"/>
            <path class="fill-percorso2" d="M121.9,13.9l-3,0V8.8l14.9,0c6.1,0,10.3,3.7,10.3,10c0,6-4.1,8.5-5.5,8.7v0.1c0,0,1,0.5,1.6,1.7l3.2,6.4
              c0.5,1,1.3,1.1,2.3,1.1h0.6l0,5.2l-3.2,0c-2.5,0-3.6-0.4-4.6-2.5l-4.3-8.4c-0.7-1.3-1.4-1.5-3.1-1.5l-3.1,0l0,12.4l-6.1,0
              L121.9,13.9z M132.9,24.3c3.1,0,4.9-1.9,4.9-5.2c0-3.3-1.8-5.1-4.8-5.1l-5,0v10.4L132.9,24.3z"/>
            <path class="fill-percorso2" d="M148.1,20.1h-2.4v-4l15.6,0c1.7,0,2.5,0.8,2.5,2.5v3.9l-4.3,0v-1.6c0-0.5-0.3-0.8-0.8-0.8l-5.8,0v6.8l8.4,0v4
              l-8.4,0v6.2c0,0.5,0.3,0.8,0.8,0.8l6,0c0.5,0,0.8-0.3,0.8-0.8v-1.6h4.3l0,3.9c0,1.8-0.8,2.5-2.5,2.5l-11.7,0
              c-1.8,0-2.5-0.7-2.5-2.5V20.1z"/>
         
            <path class="fill-percorso3" d="M211.5,40.2l19.7-27.9c1.8-2.5,3.3-4,3.3-4V8.2c0,0-1.3,0.1-3.3,0.1l-10.6,0c-0.9,0-1.4,0.5-1.4,1.4v2.7
              l-7.4,0V5.7c0-2.9,1.2-4.2,4.2-4.2l28.5,0v5.1l-19.7,28c-1.8,2.5-3.3,4-3.3,4v0.1c0,0,1.3-0.1,3.3-0.1l12,0c0.9,0,1.4-0.5,1.4-1.4
              l0-2.7h7.3v6.7c0,3-1.2,4.2-4.2,4.2l-29.8,0V40.2z"/>
            <path class="fill-percorso3" d="M249,21.8c0-0.9-0.5-1.4-1.4-1.4h-2.5l0-6.7l7.5,0c2.9,0,4.1,1.3,4.1,4.2v19.3c0,0.9,0.5,1.4,1.4,1.4h2.5v6.6
              l-7.5,0c-2.9,0-4.1-1.2-4.1-4.2L249,21.8z M249.4,1.5l6.8,0l0,7.2l-6.8,0V1.5z"/>
       
            <path class="fill-percorso2" d="M280.1,29.6c8.6,0,15.5,6.1,15.5,14.8c0,8.7-6.9,14.8-15.5,14.8c-8.6,0-15.5-6.1-15.5-14.8
              C264.7,35.8,271.5,29.6,280.1,29.6z M280.1,53.2c4.6,0,8.4-3.5,8.4-8.8c0-5.2-3.8-8.8-8.4-8.8c-4.5,0-8.4,3.6-8.4,8.8
              C271.7,49.7,275.6,53.2,280.1,53.2z"/>
            <path class="fill-percorso2" d="M299.2,37.5c0-0.8-0.4-1.2-1.2-1.2h-2.2v-6l6.5,0c2.5,0,3.7,1.2,3.7,3.2v0.8c0,0.6-0.1,1.2-0.1,1.2h0.1
              c1.2-2.4,4.3-5.9,10-5.9c6.3,0,9.9,3.3,9.9,10.7l0,11c0,0.8,0.4,1.2,1.2,1.2h2.2v5.9l-6.7,0c-2.7,0-3.8-1.1-3.8-3.8v-13
              c0-3.4-0.9-5.8-4.4-5.8c-3.8,0-6.6,2.4-7.6,5.8c-0.4,1.2-0.6,2.4-0.6,3.8l0,13l-7,0L299.2,37.5z"/>
            <path class="fill-percorso2" d="M333,25.4l-3.6,0v-6.1l23.8,0c2.6,0,3.8,1.2,3.8,3.8v6l-6.6,0v-2.4c0-0.8-0.4-1.2-1.2-1.2l-8.9,0l0,10.3
              l12.7,0v6.1l-12.7,0v9.4c0,0.8,0.4,1.2,1.2,1.2l9.1,0c0.8,0,1.2-0.4,1.2-1.2v-2.4l6.6,0v6c0,2.7-1.2,3.8-3.8,3.8l-17.8,0
              c-2.7,0-3.8-1.1-3.8-3.8L333,25.4z"/>
        </svg>
      </router-link>

      <div class="header--right app--header" v-if="isLogged" :class="{'opened' : appMenu}">

          <span v-if="isAdmin && ($route.name == 'Classe' || $route.name == 'Percorso' || $route.name == 'Lezione' || $route.name == 'Risorsa') ">
            <router-link :to="{ name: 'EditorDashboard'} " class="button button--small button--outline">Editor</router-link>
            <router-link :to="{ name: 'EditorClassi'} " class="button button--small button--outline">Classi</router-link>
            <router-link :to="{ name: 'EditorPercorsi'} " class="button button--small button--outline">Percorsi</router-link>
            <router-link :to="{ name: 'EditorUtenti'} " class="button button--small button--outline">Utenti</router-link>
          </span>

          <span v-if="isAdmin">
            <ToggleQuiz v-if="$route.name == 'Classe' || $route.name == 'Percorso'  || $route.name == 'Lezione'  || $route.name == 'Risorsa' || $route.name == 'Classe'"/>
          </span>

          <span v-if="isInsegnante || isEducatore">
            <router-link :to="{ name: 'Dashboard'} " class="button button--small button--outline">Dashboard</router-link>
            <ToggleQuiz v-if="$route.name == 'Classe' || $route.name == 'Lezione' || $route.name == 'Percorso'  || $route.name == 'Risorsa' || $route.name == 'Classe'"/>
          </span>

          <span v-if="isClasse">Classe <strong>{{ user.user.classe[0].nome }}</strong></span> 

          <strong class="user--name" v-if="user && !isClasse && user.user.nome">{{ user.user.nome }}</strong>

          <button type="button" @click="logout()" v-if="isLogged" class="button--icon">
              <svg class="icon" width="24" height="24"><use xlink:href="#logout" /></svg>
          </button>
      
      </div>
      <button class="toggleAppHeader button--icon" v-if="isLogged" @click="toggleAppMenu">
        <svg class="icon" width="24" height="24" v-if="!appMenu"><use xlink:href="#menuicon" /></svg>
        <svg class="icon" width="24" height="24" v-else><use xlink:href="#close" /></svg>
      </button>

      

      <div class="header--right" v-if="!isLogged">

          <div class="header--menu" v-if="windowWidth > 768 || showMenu">
                <a href="#progetto" class="header--link" v-smooth-scroll>Progetto</a>
                <a href="#percorsi" class="header--link" v-smooth-scroll>Percorsi</a>
                <a href="#informazioni" class="header--link" v-smooth-scroll>Informazioni</a>
          </div>
          
          <button class="button" @click="formLogin = !formLogin" v-if="loginvisibile">Entra</button>
      </div>

      <transition name="bounce" v-if="loginvisibile">
        <div v-if="formLogin" class="modal login">

          <div class="modal--header">
                <button @click="formLogin = !formLogin" class="icon--button m-l-auto">
                    <svg class="icon" width="32" height="32"><use xlink:href="#close" /></svg>
                </button>
          </div>

          <div class="modal--content">
                <img alt="Ri-creazione" src="../assets/imgs/LogoRicreazione.svg" class="logo">
                <LoginForm msg="Entra" />
          </div>
          
        </div>
      </transition>

      <transition name="fade">
            <div v-if="formLogin" class="overlay"></div>
       </transition>

  </header>
</template>


<script>
import LoginForm from '@/components/LoginForm.vue'
import ToggleQuiz from '@/components/ToggleQuiz.vue'

import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Header',

  data () {
    return {
      formLogin: false,
      scrollPos: 0,
      hideHeader: false,
      showMenu: true,
      appMenu: true,
      windowWidth: window.innerWidth,
      loginvisibile: true
    }
  },

  props: {
    msg: String
  },

  components: {
      LoginForm,
      ToggleQuiz
  },


  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user',
    ]),
  },

  methods: {
    logout () {
      this.$store.dispatch('logout')
    },

    toggleAppMenu() {
          this.appMenu = !this.appMenu
    },

    onScroll(e) {
      const scrollY = window.top.scrollY
      if(this.scrollPos < scrollY) {
        this.hideHeader = true;
      } else {
        this.hideHeader = false;
      }
      this.scrollPos = window.top.scrollY
    },

    onResize(e) {
      this.windowWidth = window.innerWidth
      if(innerWidth > 768) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
    },


  },

  mounted() {
    window.addEventListener("resize", this.onResize)
    window.addEventListener("load", this.onResize)
    window.addEventListener("scroll", this.onScroll)
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
    window.removeEventListener("load", this.onResize)
    window.removeEventListener("scroll", this.onScroll)
  }

}
</script>
