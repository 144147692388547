<template>
    <div id="appcontainer">
        <Header />
        <router-view/>
        <Icons/>
        <SuccessAlert/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import SuccessAlert from '@/components/SuccessAlert.vue'
import Icons from '@/components/Icons.vue'

export default {
  name: 'AppLayout',
  metaInfo: {
      title: 'App Ri-creazione',
      titleTemplate: '%s - Ri-creazione',
      htmlAttrs: {
        lang: 'it',
        amp: true
      }
  },
  data () {
    return {
     
    }
  },
  components: {
    Header,
    SuccessAlert,
    Icons
  },

 
}

</script>
