<template>
    <span class="dropdown" :class="dropdownClass">
        <span class="dropdownToggle" @click="toggle()">
            <slot name="toggle"></slot>
        </span>
        <transition name="fade">
        <div class="dropdownContent" v-if="dropdownShow">
            <slot name="content"></slot>
        </div>
        </transition>
    </span>
</template>

<script>
export default {
    name: 'UiDropdown',
    data() {
        return {
           dropdownShow: false,
        }
    },
    props: {
        dropdown: Boolean,
        dropdownClass: String,
    },

    methods: {
        toggle() {
            this.dropdownShow = !this.dropdownShow
            this.$emit('update:dropdownShow', !this.dropdownShow)

            setTimeout(()=>{
            this.dropdownShow = false
            },2000);

        }
    }

}
</script>