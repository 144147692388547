<template>
    <span>
        <transition name="bounce">
            <div class="modal" v-if="modal" :class="[{ 'modal--fullscreen': fullScreen }, modalClass]">
                <div class="modal--header">
                    <slot name="header"></slot>
                    <!--
                    <button class="icon--button m-l-auto" @click="handleFullScreen" v-if="!fullScreen">
                        <svg class="icon" width="22" height="22"><use xlink:href="#fullscreen" /></svg>
                    </button>
                    
                    <button class="icon--button m-l-auto" @click="handleHalfScreen" v-if="fullScreen">
                        riduci <svg class="icon" width="22" height="22"><use xlink:href="#fullscreen" /></svg>
                    </button>
                    -->

                    <button class="icon--button  m-l-auto" @click="handleClose">
                        <svg class="icon" width="22" height="22"><use xlink:href="#close" /></svg>
                    </button>
                </div>
                <div class="modal--content">
                    <slot name="content"></slot>
                    <slot name="form"></slot>
                </div>
                <div class="modal--footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="modal" class="overlay"></div>
       </transition>
    </span>
</template>

<script>
export default {
    name: 'UiModal',
    data() {
        return {
           modalShow: false,
        }
    },
    props: {
        modal: Boolean,
        modalClass: String,
        fullScreen: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleClose() {
            this.modalShow = false
            this.$emit('update:modalShow', false)
        },
        handleFullScreen() {
            console.log('full')
            this.$emit('update:fullScreen', true)
        },
        handleHalfScreen() {
            console.log('half')
            this.$emit('update:fullScreen', false)
        }
    }

}
</script>