const state = {
    user: null,
};


const mutations = {
    setRegistrazione(state, data) {
        state.registrazione = data
    }

};

const actions = {
    registrazione({ commit }, data) {
        return axios
            .post('/registrazione', data)
            .then(({ data }) => {
                commit('setRegistrazione', data)
            })
    }

};

export default {
    state,
    mutations,
    actions
}