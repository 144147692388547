<template>
    <span>
        <transition name="slideleft">
          <div v-if="message">
            <div class="alert alert--success alert--fixed">
                  {{ message }}
                  <button @click="dismiss" class="button--icon">
                    <svg class="icon" width="12" height="12"><use xlink:href="#close" /></svg>
                  </button>
            </div>
          </div>
        </transition>
    </span>
</template>

<script>

export default {
  name: 'SuccessAlert',

  data() {
    return {
      nullmessage : null
    }
  },

  computed: {
    message: {
      get: function() {
        return this.$store.state.success
      },
      set: function() {
        this.$store.state.success = null
      }
    }
  },

  watch: {
    message(val){
      if (val){
        setTimeout(()=> {
          this.message=null
        },4000);
      }
    }
  },

  methods: {
    dismiss: function() {
      this.message = null
    }
  },

}
</script>
