<template>
    <div id="site">
        <Header />
        <router-view/>
              
        <footer class="footer">
          <div class="container">
            <a  href="https://www.seitoscana.it" target="_blank" title="Sei Toscana"><LogoSeiToscana/></a>
            <div class="footer-legal">
              <div v-if="footer" v-html="footer"></div>
            </div>
            <a href="https://seitoscana.it/privacy/Informativ-privacy-progetti-comunicazione" title="Privacy Policy " target="_blank">Privacy Policy</a> | <a href="https://www.iubenda.com/privacy-policy/64911340/cookie-policy" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a> |  <a href="https://seitoscana.it/privacy" target="_blank">Informative privacy</a>
          </div>
        </footer>

        <Icons/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import LogoSeiToscana from '@/components/LogoSeiToscana.vue'
import Icons from '@/components/Icons.vue'
import axios from 'axios'

export default {
  name: 'SiteLayout',
  data () {
    return {
     footer: ''
    }
  },
  components: {
    Header,
    LogoSeiToscana,
    Icons
  },

  methods: {
    getFooter: function () {

      return axios.get('https://seitoscana.it/api/legal')
            .then((response) => {
                this.footer = response.data
            })
            .catch(e => {
             console.log(e)
      })

      /*return "<p>©2019 Sei Toscana - <strong>Servizi ecologici integrati Toscana Srl</strong><br>C.f. e p.iva 01349420529 / REA: SI-140523<br>Capitale Sociale: <i>sottoscritto</i> € 44.272.566 -<i>&nbsp;versato </i>€ 42.236.230</p>";*/
    }
  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.classList.add('home'))
  },

  mounted() {
    this.getFooter()
  }

}

</script>

<style scoped>

.footer-legal{
  min-height: 110px;
  padding: 1rem;
}

</style>